import moment from 'moment'

export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 30 },
];

// let storedSearch = window.localStorage.getItem('donation_filter')
// if (storedSearch) {
//     storedSearch = JSON.parse(storedSearch)
// }
const defaultFilter = {
    filter: {
        branch_uuid: '',
        donation_item_project: '',
        donation_item_type: '',
        donor_name: '',
        donor_id: '',
        donor_city: '',
        channel_type: '',
        channel_uuid: '',
        start_date: moment().subtract(1, 'months'),
        end_date: moment(),
        user_uuid: '',
        donor_uuid: '',
        total_amountlt: '',
        total_amountgt: '',
        bank_message: '',
        channel_trx_id: '',
        donation_item_program: '',
        department: '',
        bank: "",
        tipe_channel: "",
        date: "",
        reconcile_status: ""
        // total_amount: ''
    },
    pageNumber: 1,
    pageSize: 10,
}
export const initialFilter = defaultFilter;

export const badge = (status) => {
    let data = {};
    switch (status) {
        case 'VOID':
            data = { type: 'danger' };
            break;
        case 'REQUEST_VOID':
            data = { type: 'warning' };
            break;
        case 'SETTLE':
            data = { type: 'success' };
            break;
        default:
            data = { type: 'info' };

    }

    return data;
};

export const statusDonasi = [
    { value: 'SETTLE', name: 'SETTLE' },
    { value: 'COLLECT', name: 'COLLECT' },
    { value: 'DRAFT', name: 'DRAFT' },
    { value: 'RECOG', name: 'RECOG' },
    { value: 'VOID', name: 'VOID' },
    { value: 'REQUEST_VOID', name: 'REQUEST VOID' },
    { value: 'PENDING', name: 'PENDING' },
    { value: 'PAID', name: 'PAID' },
    { value: 'REQUEST_SETTLE', name: 'REQUEST SETTLE' },
    { value: 'PARTIAL_SETTLE', name: 'PARTIAL SETTLE' },
]

