export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 }
];

export const initialValues = {
    user_name: '',
    batch_id: '',
    channel_uuid: '',
    startDate: '',
    endDate: '',
};

export const initialFilter = {
    filter: {
        user_name: '',
        batch_id: '',
        channel_uuid: '',
        startDate: '',
        endDate: '',
    },
    pageNumber: 1,
    pageSize: 10
};