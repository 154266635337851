import React, { useMemo } from "react";
import AsynSelect from "react-select/async";
import { usePaymentUIContext } from "../../PaymentUIContext";
import { Formik } from "formik";
import { isEqual } from "lodash";

const prepareFilter = (queryParams, values) => {
    const { name, merchant_id, type, branch_uuid, bank_pooling_uuid, channel_uuid } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    if (name) filter.name = name;
    if (merchant_id) filter.merchant_id = merchant_id;
    if (type && type !== 'all') filter.type = type;
    if (branch_uuid) filter.branch_uuid = branch_uuid;
    if (bank_pooling_uuid) filter.bank_pooling_uuid = bank_pooling_uuid;
    if (channel_uuid) filter.channel_uuid = channel_uuid;

	newQueryParams.filter = filter;
	return newQueryParams;
}

export const PaymentFilter = () => {
    const typeList = ['QRIS', 'CASH', 'EDC', 'ECOMMERCE', 'THIRD-PARTY', 'OTHER'];
    const uiContext = usePaymentUIContext();
    const uiProps = useMemo(() => {
        return {
            queryParams: uiContext.queryParams,
            setQueryParams: uiContext.setQueryParams,
            initPayment: uiContext.initPayment,
            defaultBranchOptions: uiContext.defaultBranchOptions,
            loadBranchOptions: uiContext.loadBranchOptions,
            defaultBankPoolingOptions: uiContext.defaultBankPoolingOptions,
            loadBankPoolingOptions: uiContext.loadBankPoolingOptions,
            defaultChannelOptions: uiContext.defaultChannelOptions,
            loadChannelOptions: uiContext.loadChannelOptions,
        }
    }, [uiContext]);

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(uiProps.queryParams, values);
        if (!isEqual(newQueryParams, uiProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            uiProps.setQueryParams(newQueryParams);
        }
    };

    return (
        <Formik
            initialValues={uiProps.initPayment}
        >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
                <form onSubmit={handleSubmit} className="form form-label-right" style={{marginBottom: '-30px'}}>
                    <div className="form-group row mb-10">
                        <div className="col-lg-4 mb-5 mb-md-0">
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={values.name}
                                placeholder="Metode Pembayaran"
                                onChange={(e) => setFieldValue('name', e.target.value)}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan Metode Pembayaran
                            </small>
                        </div>

                        <div className="col-lg-4 mb-5 mb-md-0">
                            <input
                                type="text"
                                className="form-control"
                                value={values.merchant_id}
                                name="merchant_id"
                                placeholder="MID Device"
                                onChange={(e) => setFieldValue('merchant_id', e.target.value)}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan MID Device
                            </small>
                        </div>

                        <div className="col-lg-4 mb-5 mb-md-0">
                            <select 
                                value={values.type ?? 'all'}
                                className="form-control"
                                onChange={(e) => setFieldValue('type', e.target.value)}
                            >
                                <option value="all">All</option>    
                                {typeList.map((data) => <option value={data}>{data}</option>)}
                            </select>
                            <small className="form-text text-muted">
                                <b>Pilih</b> Berdasarkan Metode Pembayaran
                            </small>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-4 mb-5 mb-md-0">
                            <AsynSelect
                                isClearable
                                value={values.branch_uuid ? { label: values.branch_name, value: values.branch_uuid } : null}
                                placeholder={'Pilih cabang...'}
                                defaultOptions={uiProps.defaultBranchOptions}
                                loadOptions={uiProps.loadBranchOptions}
                                isLoading={!uiProps.defaultBranchOptions.length}
                                onChange={(e) => {
                                    if (e) {
                                        setFieldValue('branch_uuid', e.value);
                                        setFieldValue('branch_name', e.label);
                                    } else {
                                        setFieldValue('branch_uuid', null);
                                        setFieldValue('branch_name', null);
                                    }
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan Cabang
                            </small>
                        </div>

                        <div className="col-lg-4 mb-5 mb-md-0">
                            <AsynSelect
                                isClearable
                                placeholder={'Pilih bank pooling...'}
                                value={
                                    values.bank_pooling_uuid ? 
                                    { label: `${values.bank_pooling_account_number || ''}${values.bank_pooling_account_type ? ` | ${values.bank_pooling_account_type}` : ''}`, value: values.bank_pooling_uuid } 
                                    : null
                                }
                                defaultOptions={uiProps.defaultBankPoolingOptions}
                                loadOptions={uiProps.loadBankPoolingOptions}
                                onChange={(e) => {
                                    if (e) {
                                        setFieldValue('bank_pooling_uuid', e.value);
                                        setFieldValue('bank_pooling_account_number', e.label.split(' | ')[0]);
                                        setFieldValue('bank_pooling_account_type', e.label.includes('|') ? e.label.split(' | ')[1] : '');
                                    } else {
                                        setFieldValue('bank_pooling_uuid', null);
                                        setFieldValue('bank_pooling_account_number', null);
                                        setFieldValue('bank_pooling_account_type', null);
                                    }
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan Bank Pooling
                            </small>
                        </div>

                        <div className="col-lg-4 mb-5 mb-md-0">
                            <AsynSelect
                                isClearable
                                placeholder={'Pilih channel...'}
                                value={values.channel_uuid ? { label: values.channel_name, value: values.channel_uuid } : null} 
                                defaultOptions={uiProps.defaultChannelOptions}
                                loadOptions={uiProps.loadChannelOptions}
                                onChange={(e) => {
                                    if (e) {
                                        setFieldValue('channel_uuid', e.value);
                                        setFieldValue('channel_name', e.label);
                                    } else {
                                        setFieldValue('channel_uuid', null);
                                        setFieldValue('channel_name', null);
                                    }
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Pilih</b> Berdasarkan Channel
                            </small>
                        </div>
                    </div>

                    <div style={{ display: 'flex', gap: '5px', justifyContent: 'right', marginBottom: '1.5rem' }}>
                        <button
                            onClick={() => {
                                resetForm();
                                applyFilter(uiProps.initPayment);
                            }}
                            className="btn btn-warning btn-elevate float-right "
                        >
                            Reset
                        </button>

                        <button
                            onClick={() => applyFilter(values)}
                            className="btn btn-primary btn-elevate float-right "
                        >
                            Cari
                        </button>
                    </div>

                    <hr className="mb-10" />
                </form>
            )}
        </Formik>
    );
}