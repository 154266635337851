import React, { useEffect, useState } from "react";
// import {Redirect, Route, Switch} from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ProgressWidget } from "./Component/SecondRow/ProgressWidget";
import { DonaturTransactionWidget } from "./Component/DonaturTransactionWidget";
// import { TilesDonationWidget } from "./Component/TilesDonationWidget";
import { TilesDonorWidget } from "./Component/TilesDonorWidget";
import { WelcomeUserWidget } from "./Component/WelcomeUserWidget";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getBranchAll } from "../MasterData/_redux/branchs/branchCrud";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// import { TotalPenerimaanDonasiWidget } from "./Component/TotalPenerimaanDonasiWidget";
import {
    getTotalPenerimaanDonasi,
    getTotalDonatur,
    getTotalDonationType,
    getTotalDonationChannel,
    getTotalDonationThisYear,
    getTotalDonationThisDay,
    getTotalDonationThisWeek,
    getTotalDonationThisMonth,
    getTotalDonationProgram,
    getTotalDonationBank,
    getTotalDonationBranch,
    getTotalDonationCounter,
    getTotalDonorType,
    getTotalDonorIndividu,
    getTotalDonorInstitusi,
    getTotalDonorGender,
    getTotalNominalTransaksi,
    getTotalRetensiDonatur,
    getTargetPenerimaanDonasi,
    getTotalDonationEchannel,
    getTotalDonorPremium
} from "./crud/dashboardCrud";
import { currencyFormatter, toAbsoluteUrl } from "../../../_metronic/_helpers";
import { TileCustomWidget } from "./Component/TileCustomWidget";
import { ListsWidget14 } from "../../../_metronic/_partials/widgets";
import { TileCustomPieWidget } from "./Component/ThirdRow/TileCustomPieWidget";
import { TilesDonationWidget } from "./Component/FirstRow/TilesDonationWidget";
import { TilesRetentionDonatur } from "./Component/FirstRow/TilesRetentionDonatur";
import { TilesTotalDonaturDonorWidget } from "./Component/FirstRow/TilesTotalDonaturWidget";
import { TilesReceivedDonorWidget } from "./Component/FirstRow/TilesReceivedDonorWidget";
import { TileCustomListWidget } from "./Component/TileCustomListWidget";
import { TilesDonorTypeWidget } from "./Component/FifthRow/TilesDonorTypeWidget";
import { BarWidgetCustom } from "./Component/SixthRow/BarWidgetCustom";
import { timeChoose } from "./dashboardHelper"
import { toastOption } from '../../../_metronic/_helpers/ToastHelpers';
import { toast } from 'react-toastify';
import { actions as auth } from '../../modules/Auth/_redux/authRedux';
import { RoleChecker } from "../../RoleChecker";

export default function DashboardPage() {
    const { role } = useSelector(
        (state) => ({ role: state.auth.user.role }),
        shallowEqual,
    );
    const suhbeader = useSubheader();
    suhbeader.setTitle("Dashboard");
    const dispatch = useDispatch();

    const [filter, setFilter] = useState('now_month')
    const [Branch, setBranch] = useState([])

    const [SelectedBranch, setSelectedBranch] = useState('')

    const [TotalPenerimaanDonasi, setTotalPenerimaanDonasi] = useState(0)
    const [TotalPenerimaanDonasiLoading, setTotalPenerimaanDonasiLoading] = useState(false)
    const findTotalPenerimaanDonasi = (filter, branch) => {
        setTotalPenerimaanDonasiLoading(true)
        getTotalPenerimaanDonasi(filter, branch).then((response) => {
            setTotalPenerimaanDonasi(response.data.data)
            setTotalPenerimaanDonasiLoading(false)
        }).catch((error) => {
            if (error.response.data.error === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout());
            }
            setTotalPenerimaanDonasiLoading(false)
        })
    }

    const [TargetPenerimaanDonasi, setTargetPenerimaanDonasi] = useState(0)
    const [TargetPersenPenerimaanDonasi, setTargetPersenPenerimaanDonasi] = useState(0)
    const [TargetPertumbuhanPenerimaanDonasi, setTargetPertumbuhanPenerimaanDonasi] = useState(0)
    const [TargetPenerimaanDonasiLoading, setTargetPenerimaanDonasiLoading] = useState(false)
    const findTargetPenerimaanDonasi = (filter, branch) => {
        setTargetPenerimaanDonasiLoading(true)
        getTargetPenerimaanDonasi(filter, branch).then((response) => {
            setTargetPertumbuhanPenerimaanDonasi(response.data.data.pertumbuhan)
            setTargetPersenPenerimaanDonasi(response.data.data.persen_target)
            setTargetPenerimaanDonasi(response.data.data.target)
            setTargetPenerimaanDonasiLoading(false)
        }).catch((error) => {
            if (error.response.data.error === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout());
            }
            setTargetPenerimaanDonasiLoading(false)
        })
    }

    const [TotalDonatur, setTotalDonatur] = useState(0)
    const [DonationAverage, setDonationAverage] = useState(0)
    const [TotalDonaturLoading, setTotalDonaturLoading] = useState(false)
    const findTotalDonatur = (filter, branch) => {
        setTotalDonaturLoading(true)
        getTotalDonatur(filter, branch).then((response) => {
            setTotalDonatur(response.data.data.total_donatur)
            setDonationAverage(Math.round(Number(response.data.data.rata_rata_donasi) * 100) / 100)
            setTotalDonaturLoading(false)
        }).catch((error) => {
            setTotalDonaturLoading(false)
        })
    }

    const [DonationType, setDonationType] = useState([])
    const [DonationTypeTotal, setDonationTypeTotal] = useState([])
    const [DonationTypeLoading, setDonationTypeLoading] = useState(false)
    const findDonationType = (filter, branch) => {
        setDonationTypeLoading(true)
        getTotalDonationType(filter, branch).then((response) => {
            setDonationType(response.data.data.donation_type.map((item) => item.type))
            setDonationTypeTotal(response.data.data.donation_type.map((item) => (Number(item.total_donasi))))
            setDonationTypeLoading(false)
        }).catch((error) => {
            setDonationTypeLoading(false)
        })
    }

    const [DonationChannel, setDonationChannel] = useState([])
    const [DonationChannelTotal, setDonationChannelTotal] = useState([])
    const [DonationChannelLoading, setDonationChannelLoading] = useState(false)
    const findDonationChannel = (filter, branch) => {
        setDonationChannelLoading(true)
        getTotalDonationChannel(filter, branch).then((response) => {
            setDonationChannel(response.data.data.donation_type.map((item) => item.type))
            setDonationChannelTotal(response.data.data.donation_type.map((item) => (Number(item.total_donasi))))
            setDonationChannelLoading(false)
        }).catch((error) => {
            setDonationChannelLoading(false)
        })
    }

    const [DonationThisYearBulan, setDonationThisYearBulan] = useState([])
    const [DonationThisYearTotal, setDonationThisYearTotal] = useState([])
    const [DonationThisYearLoading, setDonationThisYearLoading] = useState(false)
    const findDonationThisYear = (filter, branch) => {
        setDonationThisYearLoading(true)
        getTotalDonationThisYear(filter, branch).then((response) => {
            setDonationThisYearBulan(response.data.data.map((item) => item.bulan))
            setDonationThisYearTotal(response.data.data.map((item) => (Number(item.total_penerimaan_donasi))))
            setDonationThisYearLoading(false)
        }).catch((error) => {
            setDonationThisYearLoading(false)
        })
    }

    const [DonationThisDayTotal, setDonationThisDayTotal] = useState(0)
    const [DonationThisDayGrowth, setDonationThisDayGrowth] = useState(0)
    const [DonationThisDayLoading, setDonationThisDayLoading] = useState(false)
    const findDonationThisDay = (filter, branch) => {
        setDonationThisDayLoading(true)
        getTotalDonationThisDay(filter, branch).then((response) => {
            // setDonationThisDay(response.data.data.donation_type.map((item) => item.type))
            setDonationThisDayTotal(response.data.data.total)
            setDonationThisDayGrowth(response.data.data.pertumbuhan)
            setDonationThisDayLoading(false)
        }).catch((error) => {
            setDonationThisDayLoading(false)
        })
    }

    const [DonationThisWeekTotal, setDonationThisWeekTotal] = useState(0)
    const [DonationThisWeekGrowth, setDonationThisWeekGrowth] = useState(0)
    const [DonationThisWeekLoading, setDonationThisWeekLoading] = useState(false)
    const findDonationThisWeek = (filter, branch) => {
        setDonationThisWeekLoading(true)
        getTotalDonationThisWeek(filter, branch).then((response) => {
            // setDonationThisWeek(response.data.data.donation_type.map((item) => item.type))
            setDonationThisWeekTotal(response.data.data.total)
            setDonationThisWeekGrowth(response.data.data.pertumbuhan)
            setDonationThisWeekLoading(false)
        }).catch((error) => {
            setDonationThisWeekLoading(false)
        })
    }

    const [DonationThisMonthTotal, setDonationThisMonthTotal] = useState(0)
    const [DonationThisMonthGrowth, setDonationThisMonthGrowth] = useState(0)
    const [DonationThisMonthLoading, setDonationThisMonthLoading] = useState(false)
    const findDonationThisMonth = (filter, branch) => {
        setDonationThisMonthLoading(true)
        getTotalDonationThisMonth(filter, branch).then((response) => {
            // setDonationThisMonth(response.data.data.donation_type.map((item) => item.type))
            setDonationThisMonthTotal(response.data.data.total)
            setDonationThisMonthGrowth(response.data.data.pertumbuhan)
            setDonationThisMonthLoading(false)
        }).catch((error) => {
            setDonationThisMonthLoading(false)
        })
    }

    const [DonationProgram, setDonationProgram] = useState([])
    const [DonationProgramLoading, setDonationProgramLoading] = useState(false)
    const findDonationProgram = (filter, branch) => {
        setDonationProgramLoading(true)
        getTotalDonationProgram(filter, branch).then((response) => {
            // setDonationProgram(response.data.data.donation_type.map((item) => item.type))
            setDonationProgram(response.data.data)
            setDonationProgramLoading(false)
        }).catch((error) => {
            setDonationProgramLoading(false)
        })
    }

    const [DonationBank, setDonationBank] = useState([])
    const [DonationBankLoading, setDonationBankLoading] = useState(false)
    const findDonationBank = (filter, branch) => {
        setDonationBankLoading(true)
        getTotalDonationBank(filter, branch).then((response) => {
            // setDonationBank(response.data.data.donation_type.map((item) => item.type))
            setDonationBank(response.data.data)
            setDonationBankLoading(false)
        }).catch((error) => {
            setDonationBankLoading(false)
        })
    }

    const [DonationEchannel, setDonationEchannel] = useState([])
    const [DonationEchannelLoading, setDonationEchannelLoading] = useState(false)
    const findDonationEchannel = (filter, branch) => {
        setDonationEchannelLoading(true)
        getTotalDonationEchannel(filter, branch).then((response) => {
            // setDonationEchannel(response.data.data.donation_type.map((item) => item.type))
            setDonationEchannel(response.data.data)
            setDonationEchannelLoading(false)
        }).catch((error) => {
            setDonationEchannelLoading(false)
        })
    }

    const [DonationBranch, setDonationBranch] = useState([])
    const [DonationBranchLoading, setDonationBranchLoading] = useState(false)
    const findDonationBranch = (filter, branch) => {
        setDonationBranchLoading(true)
        getTotalDonationBranch(filter, branch).then((response) => {
            // setDonationBranch(response.data.data.donation_type.map((item) => item.type))
            setDonationBranch(response.data.data)
            setDonationBranchLoading(false)
        }).catch((error) => {
            setDonationBranchLoading(false)
        })
    }

    const [DonationCounter, setDonationCounter] = useState([])
    const [DonationCounterLoading, setDonationCounterLoading] = useState(false)
    const findDonationCounter = (filter, branch) => {
        setDonationCounterLoading(true)
        getTotalDonationCounter(filter, branch).then((response) => {
            // setDonationCounter(response.data.data.donation_type.map((item) => item.type))
            setDonationCounter(response.data.data)
            setDonationCounterLoading(false)
        }).catch((error) => {
            setDonationCounterLoading(false)
        })
    }

    const [DonorType, setDonorType] = useState([])
    const [DonorTypeLoading, setDonorTypeLoading] = useState(false)
    const findDonorType = (filter, branch) => {
        setDonorTypeLoading(true)
        getTotalDonorType(filter, branch).then((response) => {
            // setDonorType(response.data.data.donation_type.map((item) => item.type))
            setDonorType(response.data.data)
            setDonorTypeLoading(false)
        }).catch((error) => {
            setDonorTypeLoading(false)
        })
    }

    const [DonorIndividu, setDonorIndividu] = useState([])
    const [DonorIndividuLoading, setDonorIndividuLoading] = useState(false)
    const findDonorIndividu = (filter, branch) => {
        setDonorIndividuLoading(true)
        getTotalDonorIndividu(filter, branch).then((response) => {
            setDonorIndividu(response.data.data)
            setDonorIndividuLoading(false)
        }).catch((error) => {
            setDonorIndividuLoading(false)
        })
    }

    const [DonorInstitusi, setDonorInstitusi] = useState([])
    const [DonorInstitusiLoading, setDonorInstitusiLoading] = useState(false)
    const findDonorInstitusi = (filter, branch) => {
        setDonorInstitusiLoading(true)
        getTotalDonorInstitusi(filter, branch).then((response) => {
            setDonorInstitusi(response.data.data)
            setDonorInstitusiLoading(false)
        }).catch((error) => {
            setDonorInstitusiLoading(false)
        })
    }

    const [DonorPremium, setDonorPremium] = useState([])
    const [DonorPremiumLoading, setDonorPremiumLoading] = useState(false)
    const findDonorPremium = (filter, branch) => {
        setDonorPremiumLoading(true)
        getTotalDonorPremium(filter, branch).then((response) => {
            setDonorPremium(response.data.data)
            setDonorPremiumLoading(false)
        }).catch((error) => {
            setDonorPremiumLoading(false)
        })
    }

    const [DonorGender, setDonorGender] = useState([])
    const [DonorGenderTotal, setDonorGenderTotal] = useState([])
    const [DonorGenderLoading, setDonorGenderLoading] = useState(false)
    const findDonorGender = (filter, branch) => {
        setDonorGenderLoading(true)
        getTotalDonorGender(filter, branch).then((response) => {
            setDonorGender(response.data.data.map((item) => item.gender ?? ''))
            setDonorGenderTotal(response.data.data.map((item) => (Number(item.total_donatur))))
            setDonorGenderLoading(false)
        }).catch((error) => {
            setDonorGenderLoading(false)
        })
    }

    const [NominalTransaksi, setNominalTransaksi] = useState([])
    const [NominalTransaksiLoading, setNominalTransaksiLoading] = useState(false)
    const findNominalTransaksi = (filter, branch) => {
        setNominalTransaksiLoading(true)
        getTotalNominalTransaksi(filter, branch).then((response) => {
            setNominalTransaksi(response.data.data.map((item) => item.jumlah_penerimaan_donasi))
            setNominalTransaksiLoading(false)
        }).catch((error) => {
            setNominalTransaksiLoading(false)
        })
    }

    const [DonorRetensiPersen, setDonorRetensiPersen] = useState(0)
    const [DonorRetensiTotal, setDonorRetensiTotal] = useState(0)
    const [DonorRetensiLoading, setDonorRetensiLoading] = useState(false)
    const findDonorRetensi = (filter, branch) => {
        setDonorRetensiLoading(true)
        getTotalRetensiDonatur(filter, branch).then((response) => {
            setDonorRetensiPersen(response.data.data.persen_retensi)
            setDonorRetensiTotal(response.data.data.jumlah_retensi)
            setDonorRetensiLoading(false)
        }).catch((error) => {
            setDonorRetensiLoading(false)
        })
    }

    // console.log('====================================');
    // console.log(NominalTransaksi);
    // console.log('====================================');

    useEffect(() => {
        findTotalPenerimaanDonasi(filter, SelectedBranch)
        findTotalDonatur(filter, SelectedBranch)
        findDonationType(filter, SelectedBranch)
        findDonationChannel(filter, SelectedBranch)
        findDonationProgram(filter, SelectedBranch)
        findDonationBank(filter, SelectedBranch)
        findDonationBranch(filter, SelectedBranch)
        findDonationCounter(filter, SelectedBranch)
        findDonorType(filter, SelectedBranch)
        findDonorIndividu(filter, SelectedBranch)
        findDonorInstitusi(filter, SelectedBranch)
        findDonorGender(filter, SelectedBranch)
        findNominalTransaksi(filter, SelectedBranch)
        findDonorPremium(filter, SelectedBranch)
        findDonationEchannel(filter, SelectedBranch)
    }, [filter, SelectedBranch])

    useEffect(() => {
        findDonorRetensi(filter, SelectedBranch)
        findDonationThisYear(filter, SelectedBranch)
        findDonationThisDay(filter, SelectedBranch)
        findDonationThisWeek(filter, SelectedBranch)
        findDonationThisMonth(filter, SelectedBranch)
        findTargetPenerimaanDonasi(filter, SelectedBranch)



    }, [SelectedBranch])

    useEffect(() => {
        const getBranchList = async () => {
            await getBranchAll().then((response) => {
                setBranch(response.data.data)
            }).catch((error) => {
                console.log(error);
            })
        }

        getBranchList()
    }, [])


    return (
        role !== "MitraEchannel" && <>
            {/* begin::Dashboard */}

            {/* begin::Row */}
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-xl-3 col-md-6 col-sm-6"></div>
                                <div className="col-xl-3 col-md-6 col-sm-6"></div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    {RoleChecker('Filter Cabang', 'dashboard') && <Autocomplete
                                        name="branch"
                                        style={{ backgroundColor: "white", borderRadius: "5px" }}
                                        options={Branch}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => {
                                            if (value) {

                                                setSelectedBranch(value.uuid)
                                            } else {
                                                setSelectedBranch("")
                                            }
                                        }}

                                        loading={Branch.length === 0 ? true : false}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Cari Cabang"
                                                variant="outlined"
                                                disableClearable
                                                size="small"

                                            />
                                        )}
                                    />}
                                </div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    <select
                                        className="form-control mb-2"
                                        placeholder="Filter Type"
                                        name="filter"
                                        onChange={(e) => {
                                            setFilter(e.target.value);
                                        }}
                                    >
                                        {timeChoose.map(item =>
                                            <option key={item.value}
                                                value={item.value}>{item.label}</option>
                                        )}
                                    </select>
                                    {/* <div
                                        className={`card card-custom bg-secondary gutter-b`}
                                    // style={{ height: "36px" }}
                                    >
                                        <div className="card-body">
                                            
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    <TilesDonationWidget
                                        className="gutter-b"
                                        baseColor="primary"
                                        widgetHeight="320px"
                                        persen={TargetPersenPenerimaanDonasi}
                                        loading={TargetPenerimaanDonasiLoading}
                                        pertumbuhan={TargetPertumbuhanPenerimaanDonasi}
                                        target={TargetPenerimaanDonasi}
                                        chartID="capaianTargetTahunan"
                                    />
                                </div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    <TilesReceivedDonorWidget
                                        className="gutter-b"
                                        iconColor="success"
                                        widgetHeight="320px"
                                        title="Total Penerimaan Donasi"
                                        value={currencyFormatter(TotalPenerimaanDonasi)}
                                        loading={TotalPenerimaanDonasiLoading}
                                    />
                                </div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    <TilesTotalDonaturDonorWidget
                                        className="gutter-b"
                                        iconColor="info"
                                        widgetHeight="320px"
                                        title="Total Donatur"
                                        value={TotalDonatur}
                                        subTitle={"Rata-Rata Donasi"}
                                        subValue={currencyFormatter(DonationAverage)}
                                        loading={TotalDonaturLoading}
                                        icon={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}

                                    />
                                </div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    <TilesRetentionDonatur
                                        className="gutter-b"
                                        widgetHeight="320px"
                                        chartID="retention1"
                                        total={DonorRetensiTotal}
                                        persen={DonorRetensiPersen}
                                        loading={DonorRetensiLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <ProgressWidget
                                className="gutter-b card-stretch"
                                series={DonationThisYearTotal}
                                loading={DonationThisYearLoading}
                                labels={DonationThisYearBulan}
                                chartID="donasi_tahunan"
                                value1={currencyFormatter(DonationThisDayTotal)}
                                subValue1={DonationThisDayGrowth + "%"}
                                loading1={DonationThisDayLoading}
                                value2={currencyFormatter(DonationThisWeekTotal)}
                                subValue2={DonationThisWeekGrowth + "%"}
                                loading2={DonationThisWeekLoading}
                                value3={currencyFormatter(DonationThisMonthTotal)}
                                subValue3={DonationThisMonthGrowth + "%"}
                                loading3={DonationThisMonthLoading}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-md-6">
                            <TileCustomPieWidget
                                title="Tipe Donasi"
                                className="gutter-b"
                                loading={DonationTypeLoading}
                                series={DonationTypeTotal}
                                labels={DonationType}
                                chartID="donation_type"
                            />
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <TileCustomPieWidget
                                title="Tipe Channel"
                                className="gutter-b"
                                loading={DonationChannelLoading}
                                series={DonationChannelTotal}
                                labels={DonationChannel}
                                chartID="donation_channel"
                            />
                        </div>

                        <div className="col-xl-4 col-md-12">
                            <TileCustomListWidget
                                className="gutter-b"
                                title="Top 10 Program Donasi"
                                th1="Program"
                                th2="Total"
                                data={DonationProgram}
                                loading={DonationProgramLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6">
                    <TileCustomListWidget
                        className="gutter-b"
                        title="Top 10 Bank Donasi"
                        th1="Bank"
                        th2="Total"
                        data={DonationBank}
                        loading={DonationBankLoading}

                    />
                </div>
                <div className="col-xl-4 col-md-6">
                    <TileCustomListWidget
                        className="gutter-b"
                        title="Top 10 Cabang Donasi"
                        th1="Cabang"
                        th2="Total"
                        data={DonationBranch}
                        loading={DonationBranchLoading}
                    />
                </div>
                <div className="col-xl-4 col-md-12">
                    <TileCustomListWidget
                        className="gutter-b"
                        title="Top 10 Counter Donasi"
                        th1="Counter"
                        th2="Total"
                        data={DonationCounter}
                        loading={DonationCounterLoading}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-xl-4 col-md-6">
                    <TileCustomListWidget
                        className="gutter-b"
                        title="Top 10 Donasi Echannel"
                        th1="ECHANNEL"
                        th2="Total"
                        data={DonationEchannel}
                        loading={DonationEchannelLoading}
                    />
                </div>
                {/* <div className="col-xl-4 col-md-6">
                    <TilesDonorTypeWidget data={DonorType} loading={DonorTypeLoading} widgetHeight="450px" className="gutter-b" />
                </div> */}
                {/* <div className="col-xl-3 col-md-6 col-sm-6">
                    <TilesRetentionDonatur
                        className="gutter-b"
                        widgetHeight="350px"
                        chartID="retention2"
                    />
                </div> */}
                <div className="col-xl-4 col-md-6">
                    <TileCustomListWidget
                        className="gutter-b"
                        title="Top 10 Donasi Individu"
                        th1="Nama"
                        th2="Total"
                        data={DonorIndividu}
                        loading={DonorIndividuLoading}
                    />
                </div>
                <div className="col-xl-4 col-md-12">
                    <TileCustomListWidget
                        className="gutter-b"
                        title="Top 10 Donasi Institusi"
                        th1="Institusi"
                        th2="Total"
                        data={DonorInstitusi}
                        loading={DonorInstitusiLoading}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-md-6">
                    <TileCustomPieWidget
                        title="Gender Donor"
                        className="gutter-b"
                        loading={DonorGenderLoading}
                        series={DonorGenderTotal}
                        labels={DonorGender}
                        chartID="donor_gender"
                        widgetHeight="450px"
                    />

                </div>

                <div className="col-xl-6 col-md-6">
                    <TileCustomListWidget
                        className="gutter-b"
                        title={`Top Donatur Premium ${filter === "now_month" ? "Tahun Ini" : ""}${filter === "now_year" ? "Tahun Ini" : ""}${filter === "last_year" ? "Tahun Kemarin" : ""}${filter === "five_years_ago" ? "5 Tahun Terakhir" : ""}`}
                        th1="Nama"
                        th2="Total"
                        data={DonorPremium}
                        loading={DonorPremiumLoading}
                    />
                </div>

                <div className="col-xl-12 col-md-12">
                    <BarWidgetCustom
                        title="Nominal Donasi Favorite"
                        className="gutter-b"
                        loading={NominalTransaksiLoading}
                        series={NominalTransaksi}
                        // labels={DonorGender}
                        chartID="nominal_favorite" />
                </div>

            </div>
            {/* <div className="row">
                <div className="col-xl-4">
                    <ProgressWidget className="gutter-b card-stretch" />
                </div>
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-4">
                            <TileCustomWidget
                                title="Total Penerimaan Donasi"
                                value={currencyFormatter(TotalPenerimaanDonasi)}
                                loading={TotalPenerimaanDonasiLoading}
                                icon={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                                widgetHeight="300px"
                                className="gutter-b"
                            />
                            <TilesDonationWidget
                                className="gutter-b"
                                baseColor="primary"
                                widgetHeight="250px"
                            />
                        </div>
                        <div className="col-xl-4">
                            <TileCustomWidget
                                title="Total Donatur"
                                value={TotalDonatur}
                                subTitle={"Rata-Rata Donasi"}
                                subValue={currencyFormatter(DonationAverage)}
                                loading={TotalDonaturLoading}
                                icon={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                                widgetHeight="300px"
                                iconColor="primary"
                                className="gutter-b"
                            />
                        </div>
                        <div className="col-xl-12"></div>

                        <div className="col-xl-4">
                            <TileCustomPieWidget
                                title="Tipe Donasi"
                                className="gutter-b"
                                loading={DonationTypeLoading}
                                series={DonationTypeTotal}
                                labels={DonationType}
                                chartID="donation_type"
                            />
                        </div>
                        <div className="col-xl-4">
                            <TileCustomPieWidget
                                title="Tipe Channel"
                                className="gutter-b"
                                loading={DonationChannelLoading}
                                series={DonationChannelTotal}
                                labels={DonationChannel}
                                chartID="donation_channel"
                            />
                        </div>

                        <div className="col-xl-3">
                            <ListsWidget14
                            />
                        </div>
                        <div className="col-xl-6">
                            <div className="row">
                                <div className="col-xl-6">
                                    <TilesDonationWidget className="gutter-b" baseColor="primary" widgetHeight="150px" />
                                </div>
                                <div className="col-xl-6">
                                    <TilesDonorWidget className="gutter-b" iconColor="success" widgetHeight="150px" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <WelcomeUserWidget className="gutter-b" widgetHeight="175px" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <DonaturTransactionWidget className="gutter-b" />

                        </div>
                    </div>

                </div>
            </div> */}
            {/* end::Row */}

            {/* end::Dashboard */}
        </>

    );
}
