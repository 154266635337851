import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { usePaymentUIContext } from './PaymentUIContext';
import { RoleChecker } from '../../../../RoleChecker';
import { PaymentTable } from './payment-table/PaymentTable';
import { PaymentFilter } from './payment-table/payment-filter/PaymentFilter';

export const PaymentCard = () => {
	const paymentUIContext = usePaymentUIContext();
	const paymentUIProps = useMemo(() => {
		return {
			newPaymentButtonClick: paymentUIContext.newPaymentButtonClick,
		};
	}, [paymentUIContext]);

	return (
		<Card>
			<CardHeader title="Payment List"> 
				<CardHeaderToolbar>
					{RoleChecker('Create', 'bank') && <button
						type="button"
						className="btn btn-primary"
						onClick={() => paymentUIProps.newPaymentButtonClick()}
					>
						New Payment
					</button>}
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<PaymentFilter />
				<PaymentTable />
			</CardBody>
		</Card>
	);
};
