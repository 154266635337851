import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../_metronic/_partials/controls";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { findDonaturs } from "../../../Donatur/_redux/donatur/donaturCrud";
import { genOptionLoader } from "../../../../../_metronic/_utils/option-loaders";
import { Form as BootsrapForm } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import {
  DONATION_EXPORT_TYPE,
  DONATION_STATUS,
} from "./DonationExportUIHelpers";
import { promiseTuplify } from "../../../../../_metronic/_utils/promise-utils";
import { findChannels } from "../../../MasterData/_redux/channels/channelsCrud";
import { getFundtype } from "../../../MasterData/_redux/fund_type/fundtypeCrud";
import { findProgram } from "../../../MasterData/_redux/program/programCrud";
import { findProjects } from "../../../MasterData/_redux/projects/projectsCrud";
import { getDepartmentAll } from "../../../MasterData/_redux/department/departmentCrud";
import { findUser } from "../../../MasterData/_redux/user/userCrud";
import { getBankAll } from "../../../MasterData/_redux/bank/bankCrud";
import { shallowEqual, useSelector } from "react-redux";
import { findBankPooling } from "../../../MasterData/_redux/bank-pooling/bankPoolingCrud";
import { useLoadOptions } from "../../../../hooks/use-load-options";
import * as requestPay from '../../../MasterData/_redux/payment/paymentCrud';

export function DonationExportEditForm({ initialValues, onSubmit }) {
  const { userAuth } = useSelector(
    (state) => ({ userAuth: state.auth.user }),
    shallowEqual,
  );

  const [options, setOptions] = useState({
    channel: [],
    donationType: [],
    program: [],
    project: [],
    department: [],
    users: [],
    bank: [],
    bankPooling: [],
    payment: [],
  });

  const [channelType, setChannelType] = useState("");
  const [bankUuid, setBankUuid] = useState("");

  useEffect(() => {
    fetchChannel(channelType, bankUuid);
  }, [channelType, bankUuid]);

  async function fetchChannel(type = "", bank_uuid = "") {
    const [res, error] = await promiseTuplify(
      findChannels({
        pageNumber: 1,
        pageSize: 50,
        filter: { type, bank_uuid },
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        channel: options,
      }));
    }
  }

  async function fetchBank() {
    const [res, error] = await promiseTuplify(getBankAll());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        bank: options,
      }));
    }
  }

  useEffect(() => {
    fetchDonationType();
    fetchProgram();
    fetchProject();
    fetchDepartment();
    fetchUsers();
    fetchBank();
  }, []);

  async function fetchDonationType() {
    const [res, error] = await promiseTuplify(
      getFundtype({
        pageNumber: 1,
        pageSize: 50,
        filter: {},
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data } = {} } = res;

      const options = data.items.map(({ code, name }) => ({
        label: name,
        value: code,
      }));

      setOptions((prev) => ({
        ...prev,
        donationType: options,
      }));
    }
  }

  async function fetchProgram() {
    const [res, error] = await promiseTuplify(
      findProgram({
        pageNumber: 1,
        pageSize: 50,
        filter: {},
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        program: options,
      }));
    }
  }

  async function fetchProject() {
    const [res, error] = await promiseTuplify(
      findProjects({
        pageNumber: 1,
        pageSize: 50,
        filter: {},
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        project: options,
      }));
    }
  }

  async function fetchDepartment() {
    const [res, error] = await promiseTuplify(getDepartmentAll());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        department: options,
      }));
    }
  }

  async function fetchUsers() {
    const [res, error] = await promiseTuplify(
      findUser({
        pageNumber: 1,
        pageSize: 50,
        filter: {},
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        users: options,
      }));
    }
  };

  const [defaultBankPoolingOptions, loadBankPoolingOptions] = useLoadOptions(
    (input) => findBankPooling({ filter: { account_number: input, branch_uuid: '' }, pageNumber: 1, pageSize: 15 }),
    "items",
    ({ uuid: value, account_number, account_type }) => ({ label: account_number + (account_type ? ` | ${account_type}` : ''), value })
  );

  const [defaultPaymentOptions, loadPaymentOptions] = useLoadOptions(
    (input) => requestPay.getFindPayment({ filter: { name: input }, pageNumber: 1, pageSize: 15 }),
    "items",
    ({ uuid: value, name, type, merchant_id  }) => ({ label: `${name ?? '-'} | ${type ?? '-'} | ${merchant_id !== '' ? merchant_id : '-'}`, value })
  );

  return (
    <>
      <Formik
        // enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          if (userAuth.role === 'MitraEchannel') {
            values.channel = {
              uuid: userAuth.channel_uuid,
              name: userAuth.channel.name
            }
            values.donation_department = {
              label: userAuth.channel.department.name,
              value: userAuth.channel.department.uuid
            }
          }

          onSubmit(values);
        }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Form className="form form-label-right">
              <div className="form form-group row">
                <div className="col-lg-4">
                  <select
                    name="export_type"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">--Pilih--</option>
                    {DONATION_EXPORT_TYPE.map((val) => (
                      <option key={val.value} value={val.value} hidden={!userAuth.access_role.access.find((v) => v.action === val.access)}>
                        {val.label}
                      </option>
                    ))}
                  </select>
                  <small className="form-text text-muted">
                    <b>Pilih Tipe Export</b>
                  </small>
                </div>
              </div>
              {values.export_type && (
                <>
                  <div className="form form-group row">
                    <div className="col-lg-4">
                      <DateRangePicker
                        small={true}
                        startDate={values.start_date}
                        endDate={values.end_date}
                        onDatesChange={({ startDate, endDate }) => {
                          setFieldValue("start_date", startDate);
                          setFieldValue("end_date", endDate);
                        }}
                        focusedInput={values.focusedDateInput}
                        onFocusChange={(focusedInput) =>
                          setFieldValue("focusedDateInput", focusedInput)
                        }
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                        showClearDates={true}
                        startDateId="start_date"
                        endDateId="end_date"
                        hideKeyboardShortcutsPanel={true}
                      />
                      <small className="form-text text-muted">
                        Cari rentan waktu untuk export
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <input
                        name="donation_hid"
                        placeholder="ID Donasi"
                        value={values.donation_hid}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="form-text text-muted">
                        <b>Cari </b>ID Donasi
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <input
                        name="donor_id"
                        placeholder="ID Donatur"
                        value={values.donor_id}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="form-text text-muted">
                        <b>Cari </b>ID Donatur
                      </small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <input
                        name="donor_name"
                        placeholder="Nama Donatur"
                        value={values.donor_name}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="form-text text-muted">
                        <b>Cari </b>Nama Donatur
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <input
                        name="donor_city"
                        placeholder="Kota Donatur"
                        value={values.donor_city}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="form-text text-muted">
                        <b>Cari </b>Kota Donatur
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <select
                        name="donation_status"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">ALL</option>
                        {DONATION_STATUS.map((val) => (
                          <option key={val.value} value={val.value}>
                            {val.name}
                          </option>
                        ))}
                      </select>
                      <small className="form-text text-muted">
                        <b>Cari </b>Status Donasi
                      </small>
                    </div>
                  </div>

                  {userAuth.role !== "MitraEchannel" &&
                    <>
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <select
                          name="channel_type"
                          className="form-control"
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                            setChannelType(e.target.value);
                            setFieldValue("bank", { label: null, value: null });
                            setFieldValue("channel", { uuid: null, name: null });
                          }}
                          onBlur={handleBlur}
                          >
                            <option value="">ALL</option>
                            <option value="COUNTER">COUNTER</option>
                            <option value="BANK">BANK</option>
                            <option value="ECHANNEL">ECHANNEL</option>
                          </select>
                          <small className="form-text text-muted">
                            <b>Cari</b> Tipe Channel
                          </small>
                        </div>
                        {values.channel_type == "BANK" && (
                          <div className="col-lg-4">
                            <ReactSelect
                              isClearable
                              name="bank"
                              placeholder="Pilih Bank"
                              options={options.bank.map(({ label, value }) => ({
                                label,
                                value,
                              }))}
                              value={
                                values?.bank?.value
                                  ? {
                                      label: values.bank?.label,
                                      value: values.bank?.value,
                                    }
                                  : null
                              }
                              onChange={(selected) => {
                                const { label = "", value = "" } = selected ?? {};

                                setFieldValue("bank", { label, value });
                                setFieldValue("channel", {
                                  uuid: null,
                                  name: null,
                                });
                                setBankUuid(value);
                              }}
                            />
                            <small className="form-text text-muted">
                              <b>Cari</b> Bank
                            </small>
                          </div>
                        )}
                        <div className="col-lg-4">
                          <AsyncSelect
                            name="channel"
                            defaultOptions={options.channel}
                            placeholder={"Pilih channel"}
                            loadOptions={genOptionLoader(
                              (inputValue) =>
                                findChannels({
                                  filter: {
                                    name: inputValue,
                                    type: values?.channel_type,
                                    bank_uuid: values?.bank?.value,
                                  },
                                  pageNumber: 1,
                                  pageSize: 50,
                                }),
                              "items",
                              ({ name: label, uuid: value }) => {
                                return {
                                  label,
                                  value,
                                };
                              }
                            )}
                            value={
                              values.channel && values.channel.uuid
                                ? {
                                    label: values.channel.name,
                                    value: values.channel.uuid,
                                  }
                                : null
                            }
                            onChange={({ label: name, value: uuid }) => {
                              setFieldValue("channel", { uuid, name });
                            }}
                          />
                          <small className="form-text text-muted">
                            <b>Cari</b> Nama Channel / No Rekening
                          </small>
                        </div>
                        <div className="col-lg-4">
                          <ReactSelect
                            isClearable
                            name="donation_department"
                            placeholder="Pilih Department"
                            options={options.department.map(({ label, value }) => ({
                              label,
                              value,
                            }))}
                            value={
                              values?.donation_department?.value
                                ? {
                                    label: values.donation_department?.label,
                                    value: values.donation_department?.value,
                                  }
                                : null
                            }
                            onChange={(selected) => {
                              const { label = "", value = "" } = selected ?? {};

                              setFieldValue("donation_department", {
                                label,
                                value,
                              });
                            }}
                          />
                          <small className="form-text text-muted">
                            <b>Cari</b> Department
                          </small>
                        </div>
                      </div>
                    </>
                  }
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <AsyncSelect
                        name="donation_type"
                        defaultOptions={options.donationType}
                        placeholder={"Pilih jenis donasi"}
                        loadOptions={genOptionLoader(
                          (inputValue) =>
                            getFundtype({
                              filter: {
                                name: inputValue,
                              },
                              pageNumber: 1,
                              pageSize: 50,
                            }),
                          "items",
                          ({ name: label, uuid: value }) => {
                            return {
                              label,
                              value,
                            };
                          }
                        )}
                        value={
                          values.donation_type && values.donation_type.uuid
                            ? {
                                label: values.donation_type.name,
                                value: values.donation_type.uuid,
                              }
                            : null
                        }
                        onChange={({ label: name, value: uuid }) => {
                          setFieldValue("donation_type", { uuid, name });
                        }}
                      />
                      <small className="form-text text-muted">
                        <b>Cari</b> Jenis Donasi
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <AsyncSelect
                        name="donation_program"
                        defaultOptions={options.program}
                        placeholder={"Pilih program"}
                        loadOptions={genOptionLoader(
                          (inputValue) =>
                            findProgram({
                              filter: {
                                name: inputValue,
                              },
                              pageNumber: 1,
                              pageSize: 50,
                            }),
                          "items",
                          ({ name: label, uuid: value }) => {
                            return {
                              label,
                              value,
                            };
                          }
                        )}
                        value={
                          values.donation_program &&
                          values.donation_program.uuid
                            ? {
                                label: values.donation_program.name,
                                value: values.donation_program.uuid,
                              }
                            : null
                        }
                        onChange={({ label: name, value: uuid }) => {
                          setFieldValue("donation_program", { uuid, name });
                        }}
                      />
                      <small className="form-text text-muted">
                        <b>Cari</b> Program
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <AsyncSelect
                        name="donation_project"
                        defaultOptions={options.project}
                        placeholder={"Pilih project"}
                        loadOptions={genOptionLoader(
                          (inputValue) =>
                            findProjects({
                              filter: {
                                name: inputValue,
                              },
                              pageNumber: 1,
                              pageSize: 50,
                            }),
                          "items",
                          ({ name: label, uuid: value }) => {
                            return {
                              label,
                              value,
                            };
                          }
                        )}
                        value={
                          values.donation_project &&
                          values.donation_project.uuid
                            ? {
                                label: values.donation_project.name,
                                value: values.donation_project.uuid,
                              }
                            : null
                        }
                        onChange={({ label: name, value: uuid }) => {
                          setFieldValue("donation_project", { uuid, name });
                        }}
                      />
                      <small className="form-text text-muted">
                        <b>Cari</b> Projek
                      </small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-4">
                      <select
                        name="donation_reconcile_status"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">ALL</option>
                        <option value="RECONCILED">SETTLE REKONSOLIASI</option>
                        <option value="DRAFT">DRAFT REKONSOLIASI</option>
                      </select>
                      <small className="form-text text-muted">
                        <b>Cari</b> Status Rekonsoliasi
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <input
                        name="donation_reconcile_hid"
                        placeholder="Nomor Rekonsiliasi"
                        value={values.donation_reconcile_hid}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="form-text text-muted">
                        <b>Cari </b>Nomor Rekonsiliasi
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <AsyncSelect 
                          isClearable
                          placeholder={'Pilih bank pooling'}
                          defaultOptions={defaultBankPoolingOptions}
                          loadOptions={loadBankPoolingOptions}
                          onChange={(e) => {
                              if (e && e.value) {
                                setFieldValue('donation_bank_pooling', e.value);
                              } else {
                                setFieldValue('donation_bank_pooling', null);
                              }
                          }}
                      />
                      <small className="form-text text-muted">
                        <b>Cari </b>Bank Pooling
                      </small>
                    </div>
                  </div>
                  
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <AsyncSelect
                        name="user_input"
                        defaultOptions={options.users}
                        placeholder={"Pilih user"}
                        loadOptions={genOptionLoader(
                          (inputValue) =>
                            findUser({
                              filter: {
                                name: inputValue,
                              },
                              pageNumber: 1,
                              pageSize: 50,
                            }),
                          "items",
                          ({ name: label, uuid: value }) => {
                            return {
                              label,
                              value,
                            };
                          }
                        )}
                        value={
                          values.user_input && values.user_input.uuid
                            ? {
                                label: values.user_input.name,
                                value: values.user_input.uuid,
                              }
                            : null
                        }
                        onChange={({ label: name, value: uuid }) => {
                          setFieldValue("user_input", { uuid, name });
                        }}
                      />
                      <small className="form-text text-muted">
                        <b>Cari</b> User Input
                      </small>
                    </div>
                    <div className='col-lg-4'>
                        <select
                            className="form-control"
                            name="donation_shape"
                            onBlur={handleBlur}
                            onChange={(e) => {
                                setFieldValue('donation_shape', e.target.value);
                            }}
                        >
                            <option value="">ALL</option>
                            <option value="MONEY">UANG</option>
                            <option value="GOODS">BARANG</option>
                        </select>
                        <small className="form-text text-muted">
                            <b>Cari</b> Bentuk Donasi
                        </small>
                    </div>
                    <div className="col-lg-4">
                      <AsyncSelect 
                          isClearable
                          placeholder={'Pilih payment'}
                          defaultOptions={defaultPaymentOptions}
                          loadOptions={loadPaymentOptions}
                          onChange={(e) => {
                              if (e && e.value) {
                                setFieldValue('donation_payment', e.value);
                              } else {
                                setFieldValue('donation_payment', null);
                              }
                          }}
                      />
                      <small className="form-text text-muted">
                        <b>Cari </b>Payment
                      </small>
                    </div>
                  </div>
                </>
              )}
            </Form>
            <div className="float-right">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
