import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import * as requestBank from '../../../_redux/bank/bankCrud';
import * as requestProj from '../../../_redux/projects/projectsCrud';
import * as requestDeparment from '../../../_redux/department/departmentCrud';
import * as requestBankMutation from '../../../_redux/bank-mutation/bankMutationCrud';
import BootstrapTable from "react-bootstrap-table-next";
import * as XLSX from "xlsx";
import { currencyFormatter, toastOption } from "../../../../../../_metronic/_helpers";
import AsynSelect from "react-select/async";
import { Card, CardHeader, CardBody, CardHeaderToolbar, Select } from "../../../../../../_metronic/_partials/controls";
import moment from "moment";
import { toast } from "react-toastify";
import { initialValues } from "../BankMutationUIHelpers";

export function BankMutationImport({ history }) {
    const [bank, setBank] = useState([]);
    const [kodeRek, setKodeRek] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [project, setProject] = useState([]);
    const [department, setDepartment] = useState([]);
    const [loading, setLoading] = useState(false);

    // Table columns
    const columns = [
        {
            dataField: 'no',
            text: 'No',
        },
        {
            dataField: 'tanggal',
            text: 'Tanggal',
            formatter: (cell) => {
                const jsDate = (cell - 25569) * 86400 * 1000;
                return moment(jsDate).format('DD MMM YYYY');
            }
        },
        {
            dataField: 'deskripsi',
            text: 'Keterangan',
        },
        {
            dataField: 'dana',
            text: 'Nominal',
            formatter: (cell) => currencyFormatter(+cell || 0)
        },
        {
            dataField: 'tipe_data',
            text: 'Tipe Data',
            formatter: (_, row, rowIndex) => {
                const selectedValue = row.tipe_data || '';
                const radioOptions = [
                    { value: 1, label: 'Donasi' },
                    { value: 2, label: 'Collection' },
                    { value: 3, label: 'Lainnya' },
                    { value: 4, label: 'Donasi Kurban' },
                ];

                return (
                    <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
                        {radioOptions.map((option) => (
                            <label
                                key={option.value}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '0.2rem',
                                    alignItems: 'center',
                                }}
                            >
                                <input
                                    type="radio"
                                    name={`tipeData-${rowIndex}`}
                                    value={option.value}
                                    checked={selectedValue === option.value}
                                    onChange={() => handleTipeDataChange(rowIndex, option.value)}
                                />
                                {option.label}
                            </label>
                        ))}
                    </div>
                );
            }
        },
        {
            dataField: 'tipe_deskripsi',
            text: 'Keterangan Tipe',
            formatter: (cell, row, rowIndex) => {
                return (
                    <input
                        type="text"
                        hidden={row.tipe_data !== 3}
                        value={cell || ''}
                        placeholder="Masukkan keterangan.."
                        onChange={(e) => handleKeteranganTipeChange(rowIndex, e.target.value)}
                        style={{
                            width: '100%',
                            padding: '0.5rem 1rem',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                        }}
                    />
                )
            },
        }
    ];

    const handleDownload = () => {
      const worksheet = XLSX.utils.json_to_sheet([
        {
          "no": "", 
          "tanggal": "", 
          "deskripsi": "", 
          "dana": "", 
          "atas_nama_1": "", 
          "atas_nama_2": "", 
          "atas_nama_3": "", 
          "atas_nama_4": "", 
          "atas_nama_5": "", 
          "atas_nama_6": "", 
          "atas_nama_7": "", 
          "tipe_data": "", 
          "tipe_deskripsi": ""
        },
      ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
      XLSX.writeFile(workbook, "bank_template_mutation.xlsx");
    };

    const showRekening = (event) => {
        const value = event;
        if (value === '') {
            setKodeRek([]);
        } else {
            let obj = bank.find(object => object.uuid === value);
            setKodeRek(obj.channel);
        }
    };

    const handleTipeDataChange = (rowIndex, value) => {
        setTableData((prevData) =>
            prevData.map((row, index) =>
                index === rowIndex ? { 
                    ...row, 
                    tipe_data: parseInt(value), 
                    tipe_deskripsi: '' 
                } : row
            )
        );
    };
    
    const handleKeteranganTipeChange = (rowIndex, value) => {
        setTableData((prevData) =>
            prevData.map((row, index) =>
                index === rowIndex ? { ...row, tipe_deskripsi: value } : row
            )
        );
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(sheet);
                setTableData(data);
            };
            reader.readAsBinaryString(file);
        }
    };

    const fetchBank = async () => {
        const result = await requestBank.getBankAll();
        if (result.data.data.length >= 1) setBank(result.data.data);
    };

    const fetchProject = async () => {
        const result = await requestProj.getProjectAllInOne();
        setProject(result.data.data);
    };

    const fetchProjectByQuery = (inputValue, callback) => {
        const filteredOptions = project.filter(option => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }).map((v) => ({ label: v.label, value: v.values }));

        callback(filteredOptions);
    };

    const fetchDepartment = async () => {
        const result = await requestDeparment.getDepartmentNotDeleted()
        setDepartment(result.data.data)
    };

    const handleSaveData = async(value) => {
        setLoading(true);
        // type_data check must be filled
        const validateTypeFound = tableData.every((v) => v.tipe_data !== undefined && v.tipe_data !== null);
        if (!validateTypeFound) {
            setLoading(false);
            return toast.error('Mohon cek kembali, Tipe data harus dipilih semua', toastOption);
        }
        
        // fill & adjustment data
        const fillData = tableData.map((v) => {
            const jsDate = (v.tanggal - 25569) * 86400 * 1000;
            const date = moment(jsDate).format('YYYY-MM-DD');
            return ({
                trx_date: date,
                description: v.deskripsi,
                amount: v.dana,
                type: v.tipe_data,
                type_desc: v.tipe_deskripsi || null,
                name_1: v.atas_nama_1 || '',
                name_2: v.atas_nama_2 || '',
                name_3: v.atas_nama_3 || '',
                name_4: v.atas_nama_4 || '',
                name_5: v.atas_nama_5 || '',
                name_6: v.atas_nama_6 || '',
                name_7: v.atas_nama_7 || '',
            });
        });

        value.data_import = fillData;
        // send to api
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('channel_uuid', value.channel_uuid);
        formData.append('type', value.type ?? '');
        formData.append('program_uuid', value.program_uuid ?? '');
        formData.append('project_uuid', value.project_uuid ?? '');
        formData.append('department_uuid', value.department_uuid)
        formData.append('send_notif', value.send_notif);
        formData.append('data_import', JSON.stringify(value.data_import));

        try {
            const result = await requestBankMutation.importBankMutation(formData);
            toast.success(result.data.message, toastOption);
            setLoading(false);

            // reload sekitar 1,5 detik
            setTimeout(() => {
                history.push('/mastd-bank/bank-mutation')
            }, 1000);

        } catch (error) {
            console.log('cek data', error.response);
            toast.error(error.response.data.message, toastOption);
            setLoading(false);
        }
        
    };

    useEffect(() => {
        fetchProject();
        fetchBank();
        fetchDepartment();
    }, []);

    return (
        <Card>
            <CardHeader title={'Import Mutasi Bank'}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-success mr-2"
                        onClick={handleDownload}
                    >
                        Download Template
                    </button>

                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => history.push('/mastd-bank/bank-mutation')}
                    >
                      <i className="fa fa-arrow-left"></i>
                      Back
                    </button>
                </CardHeaderToolbar>
            </CardHeader>

            <CardBody>
                {/* Input Data */}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(value) => handleSaveData(value)}
                >
                    {({ handleSubmit, setFieldValue, values }) => (
                        <>
                            {/* Filter */}
                            <div className="form-group row">
                                {/* Select Project */}
                                <div className="col-lg-6">
                                    <label>Project ( Nama Project | Nama Program | Nama Jenis Dana )</label>
                                    <AsynSelect
                                        placeholder={'Pilih Project...'}
                                        defaultOptions={project.map((v) => ({ label: v.label, value: v.values }))}
                                        loadOptions={(input, callback) => fetchProjectByQuery(input, callback)}
                                        onChange={(e) => {
                                            const label = e.label;
                                            const projectName = label.split(' | ')[0];
                                            const programName = label.split(' | ')[1];
                                            const fundTypeName = label.split(' | ')[2];
                                            const value = e.value;
                                            const projectId = value.split(' | ')[0];
                                            const programId = value.split(' | ')[1];
                                            const fundType = value.split(' | ')[2];
                                            setFieldValue('project_name', projectName)
                                            setFieldValue('project_uuid', projectId)
                                            setFieldValue('program_name', programName)
                                            setFieldValue('program_uuid', programId)
                                            setFieldValue('fundtype', fundTypeName)
                                            setFieldValue('type', fundType)
                                        }}
                                    />
                                    <label>Harap Pilih <b>Project</b> Jika terdapat Donasi Bank / tipe_data = 1</label>
                                    <ErrorMessage name='values'
                                        render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                                </div>

                                {/* Select Bank */}
                                <div className="col-lg-3">
                                    <label>*Select Bank</label>
                                    <Autocomplete
                                        name="bank"
                                        options={bank}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(_, value) => {
                                            if (value) {
                                                showRekening(value.uuid, setFieldValue)
                                                setFieldValue('bank', value.uuid)
                                            } else {
                                                setFieldValue('bank', "")
                                                showRekening("", setFieldValue)
                                            }
                                        }}
                                        value={bank.length >= 1 && bank.find((item) => item.uuid === values.bank)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Pilih Bank"
                                                variant="outlined"
                                                disableClearable
                                                size="small"
                                            />
                                        )}
                                    />
                                    <label>Harap Pilih <b>Bank</b></label>
                                    <ErrorMessage name='bank'
                                        render={msg => <div style={{ color: 'red', }}>{msg}</div>} />
                                </div>

                                {/* No Rekening */}
                                <div className="col-lg-3">
                                    <label>*Select Kode Rekening</label>
                                    <Autocomplete
                                        name="channel_uuid"
                                        options={kodeRek}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => {
                                            if (value) {
                                                setFieldValue('channel_uuid', value.uuid)
                                                setFieldValue('department_uuid', value.department_uuid)
                                            } else {
                                                setFieldValue('channel_uuid', "")
                                                setFieldValue('department_uuid', "")
                                            }
                                        }}
                                        value={kodeRek.length > 0 && kodeRek.find((item) => item.uuid === values.channel_uuid)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Pilih Kode Rekening"
                                                variant="outlined"
                                                disableClearable
                                                size="small"
                                            />
                                        )}
                                    />
                                    <label>Harap Pilih <b>Kode Rekening</b></label>
                                    <ErrorMessage name='channel_uuid'
                                        render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                                </div>
                            </div>

                            <div className="form-group row">
                                {/* Department */}
                                <div className="col-lg-3">
                                    <Select
                                        name="department_uuid"
                                        label="Departemen"
                                        onChange={(e) => {
                                            setFieldValue('department_uuid', e.target.value)
                                        }}
                                    >
                                        <option value="">Pilih</option>
                                        {
                                            department.map(row =>
                                                <option key={row.uuid} value={row.uuid}>{row.name}</option>,
                                            )
                                        }
                                    </Select>
                                </div>

                                {/* Send Notif */}
                                <div className="col-lg-3">
                                    <Select
                                        name="send_notif"
                                        label="*Kirim Notifikasi"
                                        onChange={(e) => {
                                            setFieldValue('send_notif', e.target.value)
                                        }}
                                    >
                                        <option value="">Pilih</option>
                                        <option value="SEND">Ya</option>
                                        <option value="DONT_SEND">Tidak</option>
                                    </Select>
                                </div>

                                {/* Upload File */}
                                <div className="col-lg-4">
                                    <Form.Group>
                                        <Form.File
                                            id="file" 
                                            label="*Mutasi Bank File" 
                                            onChange={(event) => {
                                                handleFileUpload(event)
                                                setFieldValue('file', event.currentTarget.files[0])
                                            }} 
                                        />
                                        <ErrorMessage name='file'
                                            render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                                    </Form.Group>
                                </div>
                            </div>

                            <hr />

                            {/* Table */}
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="no"
                                data={tableData || []}
                                columns={columns}
                            />


                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    disabled={ !values.channel_uuid || !values.send_notif ||tableData.length <= 0 || loading }
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="btn btn-primary btn-elevate"
                                    style={{ textAlign: 'right'}}
                                >
                                    Proses
                                </Button>
                            </div>
                        </>
                    )}
                </Formik>                
            </CardBody>
        </Card>
    );
};