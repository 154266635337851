import axios from 'axios';
import { DonationURL } from '../../../../config/UrlEndPoint';

export const URL = `${DonationURL}bank_mutation`;

export function getFindBankMutation(payload) {
    return axios.post(`${URL}/v2/find`, payload);
}

export function getFindBankMutationItem(payload) {
    return axios.post(`${URL}/v2/find-item`, payload);
}

export function getFindBankMutationById(uuid) {
    return axios.post(`${URL}/v2/${uuid}/detail`);
}

export function insertBankMutation(body) {
    return axios.get(`${URL}`, body);
}

export function importBankMutation(body) {
    return axios.post(`${URL}/v2/import`, body);
}

export function updateBankMutation(body, uuid) {
    return axios.put(`${URL}/${uuid}`, body);
}

export function deleteBankMutation(uuid) {
    return axios.delete(`${URL}/${uuid}`);
}




