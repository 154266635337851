import moment from 'moment'

export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 }
];

export const badge = (status) => {
    let data = {};
    switch (status) {
        case 'Failure':
            data = { type: 'danger' };
            break;
        case 'Banned':
            data = { type: 'warning' };
            break;
        case 'Success':
            data = { type: 'success' };
            break;
        default:
            data = { type: 'info' };

    }

    return data;
};

export const initialFilter = {
    filter: {
        name: '',
        type: '',
        merchant_id: '',
        bank_pooling_uuid: '',
        channel_uuid: '',
        branch_uuid: '',
    },
    pageNumber: 1,
    pageSize: 10
};
