import React, { createContext, useCallback, useContext, useState } from "react";
import { initialFilter } from './BankMutationUIHelpers'
import { findChannels } from '../../_redux/channels/channelsCrud';
import { isEqual, isFunction } from 'lodash';
import { useLoadOptions } from "../../../../hooks/use-load-options";

const BankMutationUIContext = createContext();

export function useBankMutationUIContext() {
    return useContext(BankMutationUIContext);
}

export const BankMutationUIConsumer = useBankMutationUIContext.Consumer;

export function BankMutationUIProvider({ uiEvent, children }) {
    const [showUpload, setShowUpload] = useState(false);
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initDate = {
		start_create_date: null,
		end_create_date: null,
	};

    const [date, setDate] = useState(initDate);
    const [onFocused, setOnFocused] = useState(null);

    const [defaultChannelOptions, loadChannelOptions] = useLoadOptions(
        (input) => findChannels({ filter: { name: input }, pageNumber: 1, pageSize: 15 }),
        "items",
        ({ uuid: value, name: label }) => ({ label, value })
    );
    
    const value = {
        uiEvent,
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        showUpload, 
        setShowUpload,
        date, 
        setDate,
        onFocused, 
        setOnFocused,
        defaultChannelOptions, 
        loadChannelOptions,
    };

    return (
        <BankMutationUIContext.Provider value={value}>
            {children}
        </BankMutationUIContext.Provider>
    );
}