import React, { useMemo } from "react";
import { useBankMutationUIContext } from "../BankMutationUIContext";
import * as uiHelper from '../BankMutationUIHelpers';
import AsynSelect from "react-select/async";
import { isEqual } from "lodash";
import { Formik } from "formik";
import { DateRangePicker } from "react-dates";

const prepareFilter = (queryParams, values) => {
    const { batch_id, user_name, channel_uuid, start_create_date, end_create_date } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    if (batch_id) filter.batch_id = batch_id;
    if (user_name) filter.user_name = user_name;
    if (channel_uuid) filter.channel_uuid = channel_uuid;
    if (start_create_date) filter.start_create_date = start_create_date;
    if (end_create_date) filter.end_create_date = end_create_date;

	newQueryParams.filter = filter;
	return newQueryParams;
}

export const BankMutationFilter = () => {
    const uiContext = useBankMutationUIContext();
    const uiProps = useMemo(() => {
        return {
            queryParams: uiContext.queryParams,
            setQueryParams: uiContext.setQueryParams,
            defaultChannelOptions: uiContext.defaultChannelOptions,
            loadChannelOptions: uiContext.loadChannelOptions,
            date: uiContext.date,
            setDate: uiContext.setDate,
            onFocused: uiContext.onFocused,
            setOnFocused: uiContext.setOnFocused,
        }
    }, [uiContext]);

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(uiProps.queryParams, values);
        if (!isEqual(newQueryParams, uiProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            uiProps.setQueryParams(newQueryParams);
        }
    };

    return (
        <Formik
            initialValues={uiHelper.initialValues}
        >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
                <form onSubmit={handleSubmit} className="form form-label-right" style={{marginBottom: '-30px'}}>
                    <div className="form-group row mb-10">
                        <div className="col-lg-3 mb-5 mb-md-0">
                            <input
                                type="text"
                                className="form-control"
                                value={values.batch_id}
                                name="batch_id"
                                placeholder="No Batch"
                                onChange={(e) => setFieldValue('batch_id', e.target.value)}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan No Batch
                            </small>
                        </div>

                        <div className="col-lg-3 mb-5 mb-md-0">
                            <input
                                type="text"
                                className="form-control"
                                name="user_name"
                                value={values.user_name}
                                placeholder="Nama PIC"
                                onChange={(e) => setFieldValue('user_name', e.target.value)}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan Nama PIC
                            </small>
                        </div>

                        <div className="col-lg-3 mb-5 mb-md-0">
                            <AsynSelect
                                isClearable
                                placeholder={'Pilih channel...'}
                                value={values.channel_uuid ? { label: values.channel_name, value: values.channel_uuid } : null} 
                                defaultOptions={uiProps.defaultChannelOptions}
                                loadOptions={uiProps.loadChannelOptions}
                                onChange={(e) => {
                                    if (e) {
                                        setFieldValue('channel_uuid', e.value);
                                        setFieldValue('channel_name', e.label);
                                    } else {
                                        setFieldValue('channel_uuid', null);
                                        setFieldValue('channel_name', null);
                                    }
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan Channel
                            </small>
                        </div>

                        <div className="col-lg-3 mb-5 mb-md-0">
                            <DateRangePicker
                                small={true}
                                startDate={uiProps.date.start_create_date}
                                endDate={uiProps.date.end_create_date}
                                onDatesChange={({ startDate, endDate }) => {
                                    uiProps.setDate({
                                        start_create_date: startDate,
                                        end_create_date: endDate
                                    });
                                    setFieldValue('start_create_date', startDate)
                                    setFieldValue('end_create_date', endDate)
                                }}
                                focusedInput={uiProps.onFocused}
                                onFocusChange={focusedInput => uiProps.setOnFocused(focusedInput)}
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                                showClearDates={true}
                                startDateId="start_create_date"
                                endDateId="end_create_date"
                                hideKeyboardShortcutsPanel={true}
                            />
                            <small className="form-text text-muted ">
                                Cari Berdasarkan <b>Periode Dibuat</b>
                            </small>
                        </div>
                    </div>

                    <div style={{ display: 'flex', gap: '5px', justifyContent: 'right', marginBottom: '1.5rem' }}>
                        <button
                            onClick={() => {
                                resetForm();
                                applyFilter(uiHelper.initialValues);
                            }}
                            className="btn btn-warning btn-elevate float-right "
                        >
                            Reset
                        </button>

                        <button
                            onClick={() => applyFilter(values)}
                            className="btn btn-primary btn-elevate float-right "
                        >
                            Cari
                        </button>
                    </div>

                    <hr className="mb-10" />
                </form>
            )}
        </Formik>
    );
}