import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { Input, Select } from '../../../../../../_metronic/_partials/controls';
import * as Yup from 'yup';
import AsynSelect from "react-select/async";
import { findBranchs } from '../../../_redux/branchs/branchCrud';
import { findChannels } from '../../../_redux/channels/channelsCrud';
import { findBankPooling } from '../../../_redux/bank-pooling/bankPoolingCrud';
import { useLoadOptions } from '../../../../../hooks/use-load-options';

export function PaymentForm({ payment, onHide, onSubmit }) {
    const typeList = ['QRIS', 'CASH', 'EDC', 'ECOMMERCE', 'THIRD-PARTY', 'OTHER'];
    const [channels, setChannels] = useState([]);
    const [bankPoolings, setBankPoolings] = useState([]);
    const [branchId, setBrachId] = useState('');

    const [defaultBranchOptions, loadBranchOptions] = useLoadOptions(
        (input) => findBranchs({ filter: { name: input }, pageNumber: 1, pageSize: 15 }),
        "items",
        ({ uuid: value, name: label }) => ({ label, value })
    );

    const [defaultBankPoolingOptions, loadBankPoolingOptions] = useLoadOptions(
        (input) => {
            if (!branchId) return [];
            return findBankPooling({ filter: { account_number: input, branch_uuid: branchId }, pageNumber: 1, pageSize: 15 });
        },
        "items",
        ({ uuid: value, account_number, account_type }) => ({ label: account_number + (account_type ? ` | ${account_type}` : ''), value })
    );

    const [defaultChannelOptions, loadChannelOptions] = useLoadOptions(
        (input) => {
            if (!branchId) return [];
            return findChannels({ filter: { name: input, branch_uuid: branchId }, pageNumber: 1, pageSize: 15 });
        },
        "items",
        ({ uuid: value, name: label }) => ({ label, value })
    );

    const PaymentEditSchema = Yup.object().shape({
        name: Yup.string()
            .required('Metode Pembayaran harus diisi')
            .max(100, 'Metode Pembayaran tidak boleh lebih dari 100 karakter'),

        type: Yup.string()
            .required('Tipe harus dipilih'),

        bank_pooling_uuid: Yup.string()
            .required('Bank Pengumpul harus dipilih'),

        channel_uuid: Yup.string()
            .required('Channel harus dipilih'),

        branch_uuid: Yup.string()
            .required('Cabang harus dipilih'),
    });

    const firstFetchData = async (branchId) => {
        try {
            if (defaultChannelOptions.length <= 0) {
                const result = await findChannels({ filter: { branch_uuid: branchId }, pageNumber: 1, pageSize: 15 });
                const resMap = result.data?.data?.items?.map((v) => ({ label: v.name, value: v.uuid }));
                setChannels(resMap);
            }

            if (defaultBankPoolingOptions.length <= 0) {
                const result = await findBankPooling({ filter: { branch_uuid: branchId }, pageNumber: 1, pageSize: 15 });
                const resMap = result.data?.data?.items?.map((v) => ({ label: v.account_number + (v.account_type ? ` | ${v.account_type}` : ''), value: v.uuid }));
                setBankPoolings(resMap);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        if (branchId && defaultChannelOptions.length <= 0 && defaultBankPoolingOptions.length <= 0) firstFetchData(branchId);
    }, [branchId, defaultChannelOptions, defaultBankPoolingOptions])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={payment}
            validationSchema={PaymentEditSchema}
            onSubmit={(values) => onSubmit(values)}
        >
            {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <>
                    <Modal.Body className="overlay overlay-block cursor-default">
                        <Form className="form form-label-right">
                            {/* Payment Name */}
                            <div className="col-lg-12 mb-6">
                                <Field
                                    name="name"
                                    component={Input}
                                    placeholder="Metode Pembayaran"
                                    label="Metode Pembayaran"
                                />
                            </div>
                            
                            {/* Payment Type */}
                            <div className="col-lg-12 mb-6">
                                <Select
                                    name="type"
                                    label="Type"
                                    onChange={handleChange}
                                    value={values.type}
                                >
                                    <option value="">--PILIH--</option>
                                    {
                                        typeList.map(v =>
                                            <option key={v} value={v}>{v}</option>
                                        )
                                    }
                                </Select>
                                <ErrorMessage name='type' render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                            </div>

                            {/* MID Device */}
                            <div className="col-lg-12 mb-6">
                                <Field
                                    name="merchant_id"
                                    component={Input}
                                    placeholder="MID Device"
                                    label="MID Device"
                                />
                            </div>

                            {/* Branch */}
                            <div className="col-lg-12 mb-6">
                                <label>Cabang</label>
                                <AsynSelect
                                    placeholder={'Pilih cabang...'}
                                    value={values.branch_uuid ? { label: values.branch_name, value: values.branch_uuid } : null}
                                    defaultOptions={defaultBranchOptions}
                                    loadOptions={loadBranchOptions}
                                    onChange={(e) => {
                                        setFieldValue('branch_uuid', e.value);
                                        setFieldValue('branch_name', e.label);
                                        setBrachId(e.value ?? '');
                                    }}
                                    isLoading={!defaultBranchOptions.length}
                                />
                                <ErrorMessage name='branch_uuid' render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                            </div>

                             {/* Bank Pooling */}
                             <div className="col-lg-12 mb-6">
                                <label>Bank Pengumpul</label>
                                <AsynSelect
                                    isDisabled={!branchId}
                                    placeholder={'Pilih bank pooling...'}
                                    value={
                                        values.bank_pooling_uuid ? 
                                        { label: `${values.bank_pooling_account_number || ''}${values.bank_pooling_account_type ? ` | ${values.bank_pooling_account_type}` : ''}`, value: values.bank_pooling_uuid } 
                                        : null
                                    }
                                    defaultOptions={defaultBankPoolingOptions.length >= 1 ? defaultBankPoolingOptions : bankPoolings}
                                    loadOptions={loadBankPoolingOptions}
                                    onChange={(e) => {
                                        setFieldValue('bank_pooling_uuid', e.value);
                                        setFieldValue('bank_pooling_account_number', e.label.split(' | ')[0]);
                                        setFieldValue('bank_pooling_account_type', e.label.includes('|') ? e.label.split(' | ')[1] : '');
                                    }}
                                />
                                <ErrorMessage name='bank_pooling_uuid' render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                            </div>

                            {/* Channel */}
                            <div className="col-lg-12 mb-6">
                                <label>Channel</label>
                                <AsynSelect
                                    isDisabled={!branchId}
                                    placeholder={'Pilih channel...'}
                                    value={values.channel_uuid ? { label: values.channel_name, value: values.channel_uuid } : null} 
                                    defaultOptions={defaultChannelOptions.length >= 1 ? defaultChannelOptions : channels}
                                    loadOptions={loadChannelOptions}
                                    onChange={(e) => {
                                        setFieldValue('channel_uuid', e.value);
                                        setFieldValue('channel_name', e.label);
                                    }}
                                />
                                <ErrorMessage name='channel_uuid' render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                            </div>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-light btn-elevate"
                        >
                            Cancel
                        </button>
                        <> </>
                        <button
                            type="submit"
                            onClick={() => handleSubmit()}
                            className="btn btn-primary btn-elevate"
                        >
                            Save
                        </button>
                    </Modal.Footer>
                </>
            )}
        </Formik>
    );
}