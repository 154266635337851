// export const AuthURL = "https://sandra.dompetdhuafa.org/api/auth/"; // 3000
// export const DonorURL = "https://sandra.dompetdhuafa.org/api/dono/"; // 3001
// export const MasterDataURL = "https://sandra.dompetdhuafa.org/api/mastd/"; // 3002
// export const DonationURL = "https://sandra.dompetdhuafa.org/api/dona/"; // 3003
// export const DonaturURL = "https://donatur.dev.dompetdhuafa.org/api/"; // 3010

export const AuthURL = "https://admin.sandra-dev.dompetdhuafa.org/api/auth/";
export const DonorURL = "https://admin.sandra-dev.dompetdhuafa.org/api/dono/";
export const MasterDataURL = "https://admin.sandra-dev.dompetdhuafa.org/api/mastd/";
export const DonationURL = "https://admin.sandra-dev.dompetdhuafa.org/api/dona/";
// export const PublicReportURL = "https://admin.sandra-dev.dompetdhuafa.org/report/";

// export const AuthURL = 'http://localhost:3000/';
// export const DonorURL = 'http://localhost:3001/';
// export const MasterDataURL = 'http://localhost:3002/';
// export const DonationURL = 'http://localhost:3003/';
// export const DonaturURL = 'http://localhost:3010/';

// export const AuthURL = "https://sandra.dompetdhuafa.org/api/auth/";
// export const DonorURL = "https://sandra.dompetdhuafa.org/api/dono/";
// export const MasterDataURL = "https://sandra.dompetdhuafa.org/api/mastd/";
// export const DonationURL = "https://sandra.dompetdhuafa.org/api/dona/";
export const PublicReportURL = "https://sandra.dompetdhuafa.org/report/";
export const ConsolidationJobURL = "https://report-consolidation.sandra.dompetdhuafa.org/";

// export const AuthURL = 'http://localhost:3000/';
// export const DonorURL = "http://localhost:3001/";
// export const MasterDataURL = "http://localhost:3002/";
// export const DonationURL = "http://localhost:3003/";
// export const DonaturURL = "http://localhost:3010/";
// export const PublicReportURL = "http://localhost:3005/"
// export const ConsolidationJobURL = "http://localhost:3006/";

// export const AuthURL = 'http://192.168.100.40:3000/';
// export const DonorURL = 'http://192.168.100.40:3001/';
// export const MasterDataURL = 'http://192.168.100.40:3002/';
// export const DonationURL = 'http://192.168.100.40:3003/';
// export const DonaturURL = 'http://192.168.100.40:3010/';
// export const PublicReportURL = "http://192.168.100.40:3005/"
