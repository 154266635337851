import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './PaymentUIHelpers'
import { findBranchs } from '../../_redux/branchs/branchCrud';
import { findChannels } from '../../_redux/channels/channelsCrud';
import { findBankPooling } from '../../_redux/bank-pooling/bankPoolingCrud';
import { useLoadOptions } from '../../../../hooks/use-load-options';

const PaymentUIContext = createContext();

export function usePaymentUIContext() {
    return useContext(PaymentUIContext);
}

export const PaymentUIConsumer = PaymentUIContext.Consumer;

export function PaymentUIProvider({ paymentUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initPayment = {
        name: '',
        type: '',
        merchant_id: '',
        bank_pooling_uuid: '',
        channel_uuid: '',
        branch_uuid: '',
    };

    const [defaultBranchOptions, loadBranchOptions] = useLoadOptions(
        (input) => findBranchs({ filter: { name: input }, pageNumber: 1, pageSize: 15 }),
        "items",
        ({ uuid: value, name: label }) => ({ label, value })
    );

    const [defaultBankPoolingOptions, loadBankPoolingOptions] = useLoadOptions(
        (input) => findBankPooling({ filter: { account_number: input }, pageNumber: 1, pageSize: 15 }),
        "items",
        ({ uuid: value, account_number, account_type }) => ({ label: account_number + (account_type ? ` | ${account_type}` : ''), value })
    );

    const [defaultChannelOptions, loadChannelOptions] = useLoadOptions(
        (input) => findChannels({ filter: { name: input }, pageNumber: 1, pageSize: 15 }),
        "items",
        ({ uuid: value, name: label }) => ({ label, value })
    );

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initPayment,
        newPaymentButtonClick: paymentUIEvents.newPaymentButtonClick,
        openEditPaymentPage: paymentUIEvents.openEditPaymentPage,
        openDeletePaymentPage: paymentUIEvents.openDeletePaymentPage,
        defaultBranchOptions, 
        loadBranchOptions,
        defaultBankPoolingOptions, 
        loadBankPoolingOptions,
        defaultChannelOptions, 
        loadChannelOptions,
    };

    return (
        <PaymentUIContext.Provider value={value}>
            {children}
        </PaymentUIContext.Provider>
    );

}
